<template>
  <a-form
    ref="form"
    :model="formState"
    name="form"
    :validateOnRuleChange="false"
    :label-col="{ span: 2 }"
    :wrapper-col="{ span: 19 }"
    autocomplete="off"
    scrollToFirstError
  >
    <a-form-item
      label="标题"
      name="title"
      :rules="[{ required: true, message: '输入文章标题' }]"
    >
      <a-input v-model:value="formState.title" name="title" />
    </a-form-item>
    <a-form-item label="作者" name="author">
      <a-input v-model:value="formState.author" name="author" />
    </a-form-item>
    <a-form-item label="封面图" name="coverImage">
      <GigaUpload
        v-model:value="formState.coverImage"
        name="coverImage"
        :imageUrl="(formState.coverImage && formState.coverImage.url) || ''"
        @setImg="setImg($event, 'coverImage')"
      />
    </a-form-item>
    <a-form-item label="视频介绍" name="video">
      <GigaUpload
        v-model:value="formState.video"
        name="video"
        :videoUrl="(formState.video && formState.video.url) || ''"
        @setImg="setImg($event, 'video')"
      />
    </a-form-item>
    <a-form-item label="图片列表" name="images">
      <GigaUpload
        v-model:value="formState.images"
        name="images"
        :images="formState.images || []"
        :multiple="true"
        :needGotoUrl="true"
        @setImg="setImg($event, 'images', 'multiple')"
        @sortImg="formState.images = $event"
        @removeImg="removeImg($event, 'images')"
      />
    </a-form-item>
    <a-form-item
      label="关联产品"
      name="productIds"
    >
      <a-select
        ref="select"
        mode="multiple"
        :options="products"
        :field-names="{ label: 'name', value: 'id'}"
        optionFilterProp="name"
        v-model:value="formState.productIds"
        style="width: 100%"
      >
        <!-- <a-select-option v-for="item in products" :key="item.id" :title="item.name" :value="item.id">{{item.name}}</a-select-option> -->
      </a-select>
    </a-form-item>
    <a-form-item
        label="排序"
        name="displayOrder"
      >
        <a-input v-model:value="formState.displayOrder" name="displayOrder" />
      </a-form-item>
    <a-form-item label="文章内容" name="content">
      <Editor ref="editorOne" v-model:value="formState.content"></Editor>
    </a-form-item>
    <a-form-item>
      <a-button @click="handleCancel" style="margin-left:20px">取消</a-button>
      <a-button @click="handleOk" style="margin-left:20px">保存</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import { ref, watch } from "vue";
import GigaUpload from "../GigaUpload.vue";
import Editor from "../Editor.vue";
export default {
  name: "ArticlesForm",
  components: {
    GigaUpload,
    Editor
  },
  props: {
    formData: {
      type: Object,
      default: () => {return {}},
    },
    products: {
      type: Array,
      default: () => {return []},
    }
  },
  setup(props) {
    console.log(props)
    const formState = ref(props.formData);
    watch(() => props.formData, (newVal) => {
      formState.value = newVal
    })
    return {
      formState,
      form: ref(null),
      editorOne: ref(null)
    };
  },

  methods: {
    change(e) {
      console.log(e)
    },
    setImg(e, param, multiple) {
      let img = e[0];
      let formState = this.formState;
      if (multiple) {
        if (!formState[param] || !Array.isArray(formState[param]))
          formState[param] = [];
        formState[param] = formState[param].concat(img);
      } else {
        formState[param] = img;
      }
      console.log(formState);
      this.formState = JSON.parse(JSON.stringify(formState));
    },

    removeImg(id, param) {
      console.log(id);
      let formState = this.formState;
      let images = formState[param];
      images.forEach((e, index) => {
        if (e.id == id) {
          images.splice(index, 1);
        }
      });
      formState[param] = images;
      this.formState = JSON.parse(JSON.stringify(formState));
    },

    handleOk() {
      this.form
        .validateFields()
        .then((res) => {
          res.displayOrder = res.displayOrder ? parseInt(res.displayOrder) : 0
          let formState = Object.assign(this.formState, res);
          formState.coverImageId =
            (formState.coverImage && formState.coverImage.id) || null;
          formState.videoId = (formState.video && formState.video.id) || null;
          formState.imageIds = formState.images
            ? formState.images.map((e) => e.id)
            : [];
          let valueHtml = this.editorOne.valueHtml
          let v = valueHtml.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (matchStr) => {
            let href = matchStr.match(/\sdata-href=['"](.*?)['"]/)[1]
            if (href) {
              return `<a href="${href}">${matchStr}</a>`
            } else {
              return matchStr
            }
          })
          formState.content = v
          this.$emit("formOk", formState);
        })
        .catch((err) => {
          console.error(err);
          this.$message.error(err.errorFields[0].errors[0]);
        });
    },

    handleCancel() {
      this.$emit("formCancel");
    },
  },
};
</script>