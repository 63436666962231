<template>
  <div>
    <ArticlesForm :formData="formState" :products="products" @formCancel="handleCancel" @formOk="handleOk" />
  </div>
</template>
<script>

import { editArticle, getArticle, getProducts } from '../http/api'
import ArticlesForm from '../components/form/ArticlesForm.vue'
export default {
  components: {
    ArticlesForm
  },
  data() {
    return {
      products: [],
      formState: {},
      id: this.$route.query.id || ''
    }
  },
  created() {
    if (this.id) {
      this.getArticle()
    }
    this.getProducts()
  },
  methods: {
    getProducts() {
      getProducts({variables: {limit: 999}}).then((res) => {
        if (res.errors) {
          this.$message.error(res.errors[0].message);
          return;
        }
        let products = res.data.products;
        this.products = products;
      });
    },
    getArticle() {
      getArticle({variables: {id: this.id}}).then(res => {
        let article = res.data.article
        article.productIds = article.products.map(e => e.id)
        let content = article.content
        let c = content.replace(/<a[^>]*href=['"]([^"]*)['"][^>]*>(.*?)<\/a>/g, (matchStr, groups, con) => {
          if (con.includes('<img')) {
            return con
          } else {
            return matchStr
          }
        })
        article.content = c
        console.log(article)
        this.formState = JSON.parse(JSON.stringify(article));
      })
    },
    handleOk(e) {
      console.log(e)
      editArticle({variables: e}).then(res => {
        console.log(res)
        this.handleCancel()
      })
    },
    handleCancel() {
      this.formState = {}
      this.$router.push('/articles')
    }
  },
}
</script>
<style lang="sass" scoped>

</style>